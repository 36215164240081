
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import AppService, {
  refreshSelectMedia,
  renderImageUrl,
  renderImageUrlEditor,
  showMultipleMedia,
  showSingleMedia,
} from "@/core/services/AppService";
import Editor from "@tinymce/tinymce-vue";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import { Agency, AgencyDefault } from "@/store/model/Agency";
import { Actions } from "@/store/enums/StoreEnums";
import axios from "axios";
import Config, { default_image } from "@/core/config/AppConfig";
import JwtService from "@/core/services/JwtService";
import { content, contentDefault, locationDefault } from "@/store/model";
import { GoogleMap, Marker } from "vue3-google-map";
export default defineComponent({
  name: "edit_agency",
  props: ["id"],
  components: { GoogleMap, Marker },
  setup(props) {
    const image_editor = ref();
    const image_news = ref();
    const mapRef = ref();
    const isMultipleMedia = computed(() => store.getters["getIsMultiple"]);
    const map_api_key = process.env.VUE_APP_MAP_API_KEY;
    const centerMap = ref({ lat: 21.0362368, lng: 105.7905825 });
    const markerOptions = ref({ position: centerMap, label: "L", title: "" });
    const mark = (event) => {
      // let lang = (event.latLng.lat())
      // let lng = (event.latLng.lng())/
      // centerMap.value= {lat:event.latLng.lat(),lng:event.latLng.lng()}
      markerOptions.value = {
        position: { lat: event.latLng.lat(), lng: event.latLng.lng() },
        label: "L",
        title: "",
      };
      // centerMap.value= {lat:event.latLng.lat(),lng:event.latLng.lng()}
      objectData.value.location.latitude = event.latLng.lat();
      objectData.value.location.longitude = event.latLng.lng();
    };
    const media_actions = ref(["images", "image"]);
    let x =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.getElementsByTagName("body")[0].clientWidth;
    let y =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName("body")[0].clientHeight;

    const editorConfig = {
      image_advtab: true,
      image_caption: true,
      image_description: true,
      image_uploadtab: true,
      height: 700,
      menubar: true,
      file_picker_callback: function (callback, value, meta) {
        let url = "/manager-media?type=editor";
        const instanceApi = getTinymce().activeEditor.windowManager.openUrl({
          url: url,
          title: "Quản lý media",
          width: x * 0.8,
          height: y * 0.95,
          onMessage: (api, message) => {
            //console.log("message", message, api);
          },
          onClose: async (instance, trigger) => {
            let dataImage = JSON.parse(image_editor.value);
            //console.log(dataImage);
            let image = renderImageUrlEditor(dataImage);
            let width = dataImage.width ? dataImage.width : "800";
            if (width > 1200) width = 1000;
            callback(image, {
              text: dataImage["path"],
              alt: dataImage["originalname"],
              width: width.toString(),
              height: "auto",
            });
          },
          onAction: function (instance, trigger) {
            // code for when "custom" buttons are triggered
            //console.log("instance", instance, trigger);
          },
        });
      },
      plugins: [
        "advlist autolink lists link charmap",
        "searchreplace visualblocks code fullscreen",
        "print anchor insertdatetime media image",
        "paste code wordcount table advtable",
      ],
      toolbar:
        "insertfile |formatselect | bold italic strikethrough underline |backcolor color | subscript superscript |  alignleft aligncenter alignright alignjustify | outdent indent anchor undo redo | lists  bullist numlist outdent indent | table |image|code|fullscreen",
    };
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const listDataProvince = ref();
    const listDataDistrict = ref();
    const listDataWard = ref();
    const listDataStreet = ref();

    let objectData = ref<Agency>(
      JSON.parse(JSON.stringify(AgencyDefault)) as Agency
    );
    const account_current = ref();

    const disabledDate = (time: Date) => {
      return time.getTime() < Date.now() - 86400000;
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Chỉnh sửa đại lý ", ["Đại lý"]);
      MenuComponent.reinitialization();
      await store.dispatch(Actions.GET_AGENCY_DETAIL, props.id);
      account_current.value = store.getters["currentUser"];
      objectData.value = await store.getters["currentAgency"];
      await AppService.getDataProvince("")
        .then(async (res: any) => {
          listDataProvince.value = res;
        })
        .catch((error) => {
          console.error(error);
        });

      if (objectData.value.location) {
        if (
          typeof objectData.value.location.latitude === "string" &&
          typeof objectData.value.location.longitude === "string"
        ) {
          centerMap.value = {
            lat: parseFloat(objectData.value.location.latitude),
            lng: parseFloat(objectData.value.location.longitude),
          };
        }
        console.log(typeof objectData.value.location.latitude);

        //  markerOptions.value = {
        //   position: { lat: parseFloat(objectData.value.location.latitude), lng: objectData.value.location.longitude },
        //   label: "T",
        //   title: "",
        // };
        if (objectData.value.location.province_id) {
          await AppService.getDataProvince(
            objectData.value.location.province_id
          )
            .then(async (res: any) => {
              listDataDistrict.value = res;
            })
            .catch((error) => {
              console.error(error);
            });

          if (objectData.value.location.district_id) {
            await AppService.getDataProvince(
              objectData.value.location.district_id
            )
              .then(async (res: any) => {
                listDataWard.value = res;
              })
              .catch((error) => {
                console.error(error);
              });
            await AppService.getDataStreet(
              objectData.value.location.district_id
            )
              .then(async (res: any) => {
                listDataStreet.value = res;
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      } else {
        objectData.value.location = locationDefault;
      }
    });

    const onChangeLocation = (value) => {
      AppService.getDataProvince(value)
        .then(async (res: any) => {
          listDataDistrict.value = res;
          objectData.value.location.district_id = null;
          objectData.value.location.ward_id = null;
          objectData.value.location.street_id = null;
        })
        .catch((error) => {
          console.error(error);
        });

      let province = listDataProvince.value.filter(
        (item) => item._id === objectData.value.location.province_id
      );
      objectData.value.location.province_name = province[0].name;
    };

    const onChangeDistrict = (value) => {
      AppService.getDataProvince(value)
        .then(async (res: any) => {
          listDataWard.value = res;
          objectData.value.location.ward_id = null;
          objectData.value.location.street_id = null;
        })
        .catch((error) => {
          console.error(error);
        });
      AppService.getDataStreet(value)
        .then(async (res: any) => {
          listDataStreet.value = res;
          objectData.value.location.street_id = null;
        })
        .catch((error) => {
          console.error(error);
        });
      let district = listDataDistrict.value.filter(
        (item) => item._id === objectData.value.location.district_id
      );
      objectData.value.location.district_name = district[0].name;
    };

    const onChangeWard = (value) => {
      let ward = listDataWard.value.filter(
        (item) => item._id === objectData.value.location.ward_id
      );
      objectData.value.location.ward_name = ward[0].name;
    };

    const onChangeStreet = (value) => {
      let street = listDataStreet.value.filter(
        (item) => item._id === objectData.value.location.street_id
      );
      objectData.value.location.street_name = street[0].name;
    };

    const message = "Vui lòng không để trống";

    const required_select = {
      required: true,
      message: message,
      trigger: "change",
    };

    const rules = ref({
      name: [
        {
          required: true,
          message: "Tên  không được để trống",
          trigger: "blur",
        },
      ],
      slug: [
        {
          required: true,
          message: "Đường đẫn  không được để trống",
          trigger: "blur",
        },
      ],
      type: [
        {
          required: true,
          message: "Loại  không được để trống",
          trigger: "blur",
        },
      ],
      content: [
        {
          required: true,
          message: "Nội dung không được để trống",
          trigger: "blur",
        },
      ],
      categories: [
        {
          required: true,
          message: "Danh mục  không được để trống",
          trigger: ["blur", "change"],
        },
      ],
      published_at: [
        {
          required: true,
          message: "Ngày đăng tin không được để trống",
          trigger: "blur",
        },
      ],
      "location.province_id": [required_select],
      "location.district_id": [required_select],
      "location.ward_id": [required_select],
    });
    const convertNameToSlug = (value) => {
      objectData.value["slug"] = AppService.convertSlug(value);
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          store
            .dispatch(Actions.UPDATE_AGENCY, objectData.value)
            .then(() => {
              Swal.fire({
                text: "Cập nhật thông  thành công",
                icon: "success",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Danh sách",
                cancelButtonText: "Tiếp tục chỉnh sửa",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(async function (result) {
                if (result.isConfirmed) {
                  await router.push({ name: "agencies" });
                } else {
                  let data = await store.getters["currentAgency"];
                  await router.push({
                    name: "agencies-edit",
                    params: { id: data._id },
                  });
                }
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          return false;
        }
      });
    };

    const pinMap = async () => {
      if (objectData.value.address != "") {
        await axios
          .get(
            process.env.VUE_APP_BOOKING_URL +
              "geocoder?address=" +
              objectData.value.address +
              " " +
              objectData.value.location.street_name +
              ", " +
              objectData.value.location.ward_name +
              ", " +
              objectData.value.location.district_name +
              ", " +
              objectData.value.location.province_name +
              ", Việt Nam"
          )
          .then((res) => {
            centerMap.value = { lat: res.data["lat"], lng: res.data["lng"] };
            markerOptions.value = {
              position: centerMap.value,
              label: "T",
              title: "",
            };
            objectData.value.location.latitude = res.data["lat"];
            objectData.value.location.longitude = res.data["lng"];
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };
    watch(
      () => store.state.MediaModule.actionCallback,
      (action, second) => {
        if (media_actions.value.includes(action)) {
          let fields = action.split(".");
          if (fields.length > 1) {
            if (isMultipleMedia.value) {
              //console.log(action, fields);
              objectData.value[fields[0]][fields[1]] = objectData.value[
                fields[0]
              ][fields[1]].concat(store.getters["getSelectMultiMedia"]);
            } else {
              objectData.value[fields[0]][fields[1]] =
                store.getters["getSelectMedia"];
            }
          } else {
            if (isMultipleMedia.value) {
              objectData.value[action] = store.getters[
                "getSelectMultiMedia"
              ].concat(objectData.value[action]);
            } else {
              objectData.value[action] = store.getters["getSelectMedia"];
            }
          }
          refreshSelectMedia();
        }
      }
    );
    const removeImages = (action) => {
      let fields = action.name.split(".");
      if (fields.length > 1) {
        objectData.value[fields[0]][fields[1]].splice(action.index, 1);
      } else {
        objectData.value[fields[0]].splice(action.index, 1);
      }
    };

    const checkDefaultImage = (image) => {
      if (image.name === "default-placeholder.jpg") {
        return false;
      } else {
        return true;
      }
    };

    return {
      rules,
      submit,
      editorConfig,
      removeImages,
      checkDefaultImage,
      convertNameToSlug,
      renderImageUrl,
      showSingleMedia,
      showMultipleMedia,
      loading,
      objectData,
      formRef,
      image_editor,
      image_news,
      listDataProvince,
      listDataDistrict,
      listDataWard,
      listDataStreet,
      onChangeLocation,
      onChangeDistrict,
      onChangeWard,
      onChangeStreet,
      centerMap,
      map_api_key,
      mark,
      mapRef,
      pinMap,
      markerOptions,
      disabledDate,
    };
  },
  mounted() {
    //Event Listener for Iframe
    window.addEventListener("message", this.iframeEvent, false);
  },
  methods: {
    iframeEvent(event) {
      //Verify App Domain
      if (event.data.action == "editor") {
        this.image_editor = event.data.image;
        //console.log("data received editor: ", event.data);
      }
      if (event.data.action == "image-feature") {
        this.objectData["image"] = JSON.parse(event.data.image);
        this.image_news = JSON.parse(event.data.image);
        //console.log("image-feature: ", event.data.image);
      }
    },
  },
});
